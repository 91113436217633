import React, { useState } from 'react';
import styles from './search_farm.module.css';
import { fetchData } from '../../api';

function SearchFarmList() {
  // Стани для збереження значень полів
  const [server, setServer] = useState('');
  const [x, setX] = useState('');
  const [y, setY] = useState('');
  const [searchTargets, setSearchTargets] = useState([]);

  // Обробник подій для кнопки "Search"
  const handleSearch = async () => {
    // Перевірка наявності значень
    if (!server || !x || !y) {
      alert('Please fill all fields.');
      return;
    }

    try {
      // Виконання запиту на сервер
      const response = await fetchData("searchfarmlist  ", { server, x, y });
      // Обробка відповіді сервера
      console.log('Response:', JSON.parse(response.data));
      setSearchTargets(JSON.parse(response.data))
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
        <select
          style={{ width: '50%', height: 50 }}
          value={server}
          onChange={(e) => setServer(e.target.value)}
        >
          <option value="" disabled>Select server</option>
          <option value="ts1.x1.international.travian.com">ts1.x1.international.travian.com</option>
        </select>
        <input
          type='text'
          style={{ width: '22%', paddingLeft: 5 }}
          placeholder='X'
          value={x}
          onChange={(e) => setX(e.target.value)}
        />
        <input
          type='text'
          style={{ width: '22%', paddingLeft: 5 }}
          placeholder='Y'
          value={y}
          onChange={(e) => setY(e.target.value)}
        />
      </div>
      <button className={styles.addAccountBtn} onClick={handleSearch}>Search</button>
      <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
        <div>Distance</div>
        <div>Village</div>
        <div>Player</div>
      </div>
      {searchTargets.map((item,idx) => {
         return (
          <div style={{border:"1px solid black",padding:5,marginBottom:10, display:"flex", flexDirection:"row", alignItems:"center",flexWrap:"wrap"}} key={idx}>
            <div style={{fontSize:18, fontWeight:600}} className={styles.marginRigthTargetSearch}>{item.field1}</div> 
            <div style={{fontSize:14}} className={styles.marginRigthTargetSearch}>{item.field2}</div> 
            <div style={{color: "#ca5a29", fontSize:18}} className={styles.marginRigthTargetSearch}>{item.field3}</div> 
            <div className={styles.marginRigthTargetSearch}>{item.field4}</div>  
            <div className={styles.marginRigthTargetSearch}>{item.field5}</div> 
            <div className={styles.marginRigthTargetSearch}>{item.field6}</div>  
            <div className={styles.marginRigthTargetSearch}>{item.field7}</div> 
            <div className={styles.marginRigthTargetSearch}>{item.field8}</div> 
            <div className={styles.marginRigthTargetSearch}>{item.field9}</div> 
            <div className={styles.marginRigthTargetSearch}>{item.field10}</div>
            </div>
         ) 
        })}
    </div>
  );
}

export default SearchFarmList;
